import React from "react";

const experienceData = [
  {
    date: "November 2023 - Present",
    title: "Software Developer • Deloitte Malaysia",
    description: [
      "Orchestrating the development of Deloitte SEA tax automation system to streamline and enhance efficiency.",
      "Collaborating with cross-functional team members across the SEA region.",
      "Participating in daily scrum meetings with the manager to meticulously review and discuss essential daily tasks."
    ],
    skills: ["ASP.Net", "SSMS", "Microsoft Azure"],
    link: "https://www2.deloitte.com/my/en.html",
  },
  {
    date: "July 2022 - November 2022",
    title: "Software Engineer Intern • Fusionex Group",
    description: [
      "Worked on a microservice project that will be used in many projects.",
      "Designed the UI for the microservice project.",
      "Researched about various technologies and how to incorporate them into the project.",
      "Had frequent meetings with the team to update progress."
    ],
    skills: ["Angular", "Node.js", "C#", "MySQL"],
    link: "https://www.fusionexgroup.com/",
  },
  {
    date: "July 2022 - September 2023",
    title: "Student Ambassador • APU Malaysia",
    description: [
      "Representing APU in various events and activities.",
      "Assisting APU administration staff with administrative work.",
      "Working with international students from 15+ different countries.",
      "Occasionally offer guidance and counsel to prospective students.",
    ],
    skills: ["Communication", "Teamwork", "Compassion"],
    link: "https://www.instagram.com/p/Cr9lDTmPujq/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
  },
];

function MyExperience({ darkMode }) {
  return (
    <section
      id="myexperience"
      className={`${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
      } py-16`}
    >
      <div className="container mx-auto px-6 md:px-12">
        <h1
          className={`text-4xl md:text-5xl font-semibold mb-8 text-center font-poppins ${
            darkMode ? "text-white" : "text-blue-800"
          }`}
        >
          My Experience
        </h1>
        <div className="flex-col justify-center">
          {experienceData.map((experience, index) => (
            <div className="flex md:flex" key={index}>
              <a
                href={experience.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`block w-full rounded-lg py-4 px-6 md:p-8 transition duration-300 ease-in-out transform ${
                  darkMode
                    ? "hover:bg-gray-700 hover:text-white hover:-translate-y-1 hover:shadow-md"
                    : "hover:bg-gray-200 hover:text-gray-800 hover:-translate-y-1 hover:shadow-md"
                }`}
              >
                <div className="md:flex">
                  <div
                    className={`text-${
                      darkMode ? "white" : "gray-600"
                    } text-sm md:text-sm mr-8 md:mr-8 font-poppins w-1/4`}
                  >
                    <p
                      className={`whitespace-nowrap ${
                        darkMode ? "text-white" : ""
                      }`}
                    >
                      {experience.date}
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg md:text-xl font-semibold mb-2 font-poppins">
                      {experience.title}
                    </h2>
                    <ul
                      className={`list-disc pl-4 text-${
                        darkMode ? "white" : "gray-700"
                      } text-sm md:text-base mt-2 font-poppins`}
                    >
                      {experience.description.map((item, itemIndex) => (
                        <li
                          key={itemIndex}
                          className={`${
                            darkMode ? "text-white" : "text-gray-700"
                          }`}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                    <div className="mt-4 flex">
                      {experience.skills.map((skill, skillIndex) => (
                        <div
                          key={skillIndex}
                          className={`bg-blue-500 text-white py-1 px-3 rounded-full text-sm font-poppins mr-2 ${
                            darkMode ? "bg-blue-800" : ""
                          }`}
                        >
                          {skill}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MyExperience;
