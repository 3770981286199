import React from 'react';

function ProjectCard({ title, description, technologies, imageUrl, link, darkMode }) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={`${
        darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
      } rounded-lg overflow-hidden shadow-lg m-4 max-w-xs transform hover:scale-105 transition-transform duration-300 flex flex-col`}>
        <div className="flex-grow">
          <img src={imageUrl} alt={title} className="w-full h-40 object-cover object-center" />
        </div>
        <div className="p-4">
          <h2 className={`text-xl font-semibold mb-2 ${darkMode ? "text-white" : "text-gray-800"}`}>{title}</h2>
          <p className={`mb-2 ${darkMode ? "text-gray-400" : "text-gray-700"}`}>{description}</p>
          <div className="flex flex-wrap">
            {technologies.map((tech, index) => (
              <span
                key={index}
                className={`${
                  darkMode ? "bg-blue-700" : "bg-blue-500"
                } text-white text-xs px-2 py-1 rounded-full mr-2 mb-2`}
              >
                {tech}
              </span>
            ))}
          </div>
        </div>
      </div>
    </a>
  );
}

export default ProjectCard;
