import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./pages/Home/HomeScreen/HomePage";
import ProjectPage from "./pages/Projects/ProjectPage";

function App() {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <Router>
      <div>
        <NavBar darkMode={darkMode} setDarkMode={setDarkMode} />
        <Routes>
          <Route path="/" element={<HomePage darkMode={darkMode} />} />
          <Route path="/projects" element={<ProjectPage darkMode={darkMode} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
