import React from "react";
import { FaGithub } from "react-icons/fa";

const projectData = [
  {
    year: "2023",
    project: "Blockchain Telehealth Website",
    madeAt: "APU",
    buildWith: ["React", "Node.js", "MongoDB", "RestAPI"],
    link: "https://vhealth-junwei-fyp.onrender.com/",
  },
  {
    year: "2023",
    project: "Logistics Escrow System",
    madeAt: "APU",
    buildWith: ["Java", "JavaSwing", "Java String", "txt"],
    link: "https://github.com/JunWei25/Logistics-Escrow-System-LES-",
  },
  {
    year: "2023",
    project: "Airline Simulation",
    madeAt: "APU",
    buildWith: ["Java", "RabbitMQ"],
    link: "https://github.com/JunWei25/Airline-Simulation",
  },
  {
    year: "2022",
    project: "Vaccine Management System",
    madeAt: "APU",
    buildWith: ["Java", "JavaSwing", "Java String", "txt", "OOP"],
    link: "https://github.com/JunWei25/Vaccine-Management-System-using-OOP",
  },
  {
    year: "2022",
    project: "Order Delivery System",
    madeAt: "APU",
    buildWith: ["Java", "JavaSwing", "Java DB", "RMI", "Serialization"],
    link: "https://github.com/JunWei25/ODS-System",
  },
];

function ProjectPage({ darkMode }) {
  return (
    <div
      className={`min-h-screen ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
      }`}
    >
      <div className="container mx-auto px-6 py-12">
        <h1
          className={`text-3xl font-semibold mb-6 ${
            darkMode ? "text-white" : "text-gray-800"
          }`}
        >
          All My Projects
        </h1>
        <p
          className={`text-lg ${darkMode ? "text-gray-400" : "text-gray-600"}`}
        >
          Archive
        </p>
        <div className="overflow-x-auto">
          <table
            className={`min-w-full border-collapse border-b border-gray-300 ${
              darkMode ? "border-gray-700" : ""
            }`}
          >
            <thead>
              <tr>
                <th
                  className={`w-20 text-left py-2 border-b ${
                    darkMode ? "border-gray-700" : "border-gray-300"
                  }`}
                >
                  Year
                </th>
                <th
                  className={`text-left py-2 border-b ${
                    darkMode ? "border-gray-700" : "border-gray-300"
                  } w-50`}
                >
                  Project
                </th>
                <th
                  className={`lg:w-40 text-left py-2 border-b lg:table-cell hidden ${
                    darkMode ? "border-gray-700" : "border-gray-300"
                  }`}
                >
                  Made at
                </th>
                <th
                  className={`lg:w-60 lg:text-left py-2 border-b lg:table-cell hidden whitespace-nowrap truncate ${
                    darkMode ? "border-gray-700" : "border-gray-300"
                  }`}
                >
                  Built with
                </th>
                <th
                  className={`md:w-25 text-left py-2 border-b md:table-cell ${
                    darkMode ? "border-gray-700" : "border-gray-300"
                  }`}
                >
                  Link
                </th>
              </tr>
            </thead>
            <tbody>
              {projectData.map((project, index) => (
                <tr key={index}>
                  <td
                    className={`border-b ${
                      darkMode ? "border-gray-700" : "border-gray-300"
                    } py-3`}
                  >
                    {project.year}
                  </td>
                  <td
                    className={`font-bold border-b ${
                      darkMode ? "border-gray-700" : "border-gray-300"
                    } py-3 cursor-pointer`}
                  >
                    <a
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`text-lg font-poppins${
                        darkMode
                          ? "text-white-400 hover:text-blue-200"
                          : "text-blue-500 hover:text-blue-800"
                      }`}
                    >
                      {project.project}
                    </a>
                  </td>
                  <td
                    className={`lg:border-b ${
                      darkMode ? "border-gray-700" : "border-gray-300"
                    } py-3 lg:table-cell hidden`}
                  >
                    {project.madeAt}
                  </td>
                  <td
                    className={`lg:border-b ${
                      darkMode ? "border-gray-700" : "border-gray-300"
                    } py-3 lg:table-cell hidden whitespace-nowrap`}
                  >
                    {project.buildWith.map((item, itemIndex) => (
                      <span
                        key={itemIndex}
                        className={`${
                          darkMode
                            ? "bg-blue-900 text-white"
                            : "bg-blue-500 text-white"
                        } py-1 px-2 rounded-full text-sm font-semibold mr-2 mb-2 break-all`}
                      >
                        {item}
                      </span>
                    ))}
                  </td>
                  <td
                    className={`md:w-25 text-left py-2 border-b md:table-cell ${
                      darkMode ? "border-gray-700" : "border-gray-300"
                    }`}
                  >
                    {project.link.includes("https://github.com/") ? (
                      <a
                        href={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`cursor-pointer ${
                          darkMode
                            ? "text-blue-400 hover:text-blue-200 flex items-center"
                            : "text-blue-500 hover:text-blue-800 flex items-center"
                        }
        `}
                      >
                        <FaGithub className="mr-1" />
                        GitHub
                      </a>
                    ) : (
                      <a
                        href={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`cursor-pointer ${
                          darkMode
                            ? "text-blue-400 hover:text-blue-200 flex items-center"
                            : "text-blue-500 hover:text-blue-800 flex items-center"
                        }
        `}
                      >
                        Link
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProjectPage;
