import React from "react";
import ProjectCard from "../../components/ProjectCard";
import BlockchainTelehealth from "../../images/telemedicine-software.png";
import ODSProject from "../../images/ODS-project.png";
import VMSProject from "../../images/vms-project.jpg";

function ProjectPreview({ darkMode }) {
  const projects = [
    {
      title: "Blockchain Telehealth Website",
      description: "A telehealth website with blockchain technologies",
      technologies: ["React", "Node.js", "MongoDB", "REST API"],
      imageUrl: BlockchainTelehealth,
      link: "https://vhealth-junwei-fyp.onrender.com/",
    },
    {
      title: "Order Delivery System",
      description: "An Order Delivery System developed using Java.",
      technologies: ["Java", "Java Swing", "Java DB"],
      imageUrl: ODSProject,
      link: "https://github.com/JunWei25/ODS-System",
    },
    {
      title: "Vaccine Management System",
      description: "Developed a VMS using the 4 Pillars of OOP .",
      technologies: ["Java", "Java Swing", "Txt"],
      imageUrl: VMSProject,
      link: "https://github.com/JunWei25/Vaccine-Management-System-using-OOP",
    },
  ];

  return (
    <section
      id="projectpreview"
      className={`${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
      } py-16 relative`}
    >
      <div className="container mx-auto px-6 md:px-12 scroll-smooth flex flex-col">
        <p className="text-gray-400 mb-1 font-semibold font-poppins text-xl">
          Projects
        </p>
        <h2
          className={`text-4xl md:text-5xl font-semibold mb-4 font-poppins ${
            darkMode ? "text-white" : "text-blue-800"
          }`}
        >
          Featured Projects
        </h2>
        <div className="flex flex-wrap justify-center">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} darkMode={darkMode} />
          ))}
        </div>
        <div className="flex justify-end mt-auto">
          <a
            href="/projects"
            rel="noopener noreferrer"
            className={`${
              darkMode
                ? "text-blue-300 hover:text-blue-500"
                : "text-blue-500 hover:text-blue-700"
            } text-lg cursor-pointer`}
          >
            View All &rarr;
          </a>
        </div>
      </div>
    </section>
  );
}

export default ProjectPreview;
