import React from "react";
import MyPicture from "../../images/MyPicture.png";
import { Link } from "react-scroll";
import { useTypewriter, Cursor } from "react-simple-typewriter";

function HeroSection({ darkMode }) {
  const [typeEffect] = useTypewriter({
    words: ["Web Development", "Mobile Development", "AI", "Learning"],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 100,
  });

  return (
    <section
      className={`py-16 ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
      }`}
    >
      <div className="container mx-auto px-6 md:px-12 scroll-smooth">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 items-center">
          <div className="md:col-span-1">
            <h1 className="text-3xl md:text-5xl font-medium mb-2 md:mb-4">
              Hey! I'm Jun Wei
            </h1>
            <p className="text-lg mb-4 md:mb-6">
              I am a fresh graduate passionate about{" "}
              <span className="text-xl md:text-2xl text-blue-500 font-bold">
                {typeEffect}
              </span>
              <Cursor />
            </p>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <div className="text-center space-x-4">
                <Link
                  className={`py-2 px-4 rounded-lg transition duration-300 ease-in-out transform mb-4 inline-block ${
                    darkMode
                      ? "bg-blue-800 hover:bg-blue-950 text-white hover:-translate-y-1 hover:shadow-lg"
                      : "bg-blue-400 hover:bg-blue-500 text-white hover:-translate-y-1 hover:shadow-lg"
                  }`}
                  to="projectpreview"
                  smooth={true}
                  duration={500}
                  style={{ textDecoration: "none" }}
                >
                  View My Work
                </Link>
                <button
                  className={`py-2 px-4 rounded-lg transition duration-300 ease-in-out transform mb-4 ${
                    darkMode
                      ? "bg-blue-400 hover:bg-blue-500 text-white hover:-translate-y-1 hover:shadow-lg"
                      : "bg-blue-800 hover:bg-blue-950 text-white hover:-translate-y-1 hover:shadow-lg"
                  }`}
                  onClick={() => {
                    window.open(
                      "https://docs.google.com/document/d/1mJq-17arWXnLEs6pKycz_rw7GuH8frhLseUsMiuABho/edit?usp=sharing",
                      "_blank"
                    );
                  }}
                >
                  View My Resume
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center md:justify-end md:col-span-1">
            <img
              src={MyPicture}
              alt=""
              className="rounded-full shadow-lg max-w-xs md:max-w-md mt-4 md:mt-0 w-full h-auto"
            />
          </div>
        </div>
        <Link
          to="myexperience"
          className="cursor-pointer"
          smooth={true}
          duration={500}
        >
          <div className="text-center mt-8">
            <div className="animate-bounce inline-block">
              <div className="w-3 h-5 bg-blue-500 rounded-full"></div>
            </div>
            <p className={`${darkMode ? "text-white-500" : "text-gray-500"}`}>
              Scroll Down
            </p>
          </div>
        </Link>
      </div>
    </section>
  );
}

export default HeroSection;
