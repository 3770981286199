import React from 'react';

function ProgressBar({ progress }) {
  // Check if progress is 100%
  const isCompleted = progress === 100;

  return (
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div>
          {isCompleted ? (
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
              Completed!
            </span>
          ) : (
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
              In Progress
            </span>
          )}
        </div>
        <div className="text-right">
          <span className="text-xs font-semibold inline-block text-teal-600">
            {`${progress}%`}
          </span>
        </div>
      </div>
      <div className="flex h-2 mb-4 overflow-hidden text-xs bg-teal-200">
        <div
          style={{ width: `${progress}%` }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
