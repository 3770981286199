import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaSun, FaMoon } from "react-icons/fa";

function NavBar({ darkMode, setDarkMode }) {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setDarkMode(!darkMode);
    setIsChecked(!isChecked);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div
      className={`relative ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
      }`}
    >
      {isMobileMenuOpen && (
        <div
          className={`fixed top-16 left-0 w-full h-full z-50`}
          onClick={toggleMobileMenu}
        ></div>
      )}
      <nav className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 relative z-40">
        <div className="relative flex h-20 items-center justify-between">
          <div className="hidden sm:block ml-auto px-3">
            <div className="flex space-x-6">
              <a
                href="/"
                className={`rounded-md px-6 py-2 text-md font-large ${
                  location.pathname === "/"
                    ? "bg-blue-800 text-white shadow-md"
                    : "hover:bg-blue-400 hover:text-white hover:shadow-md"
                }`}
                aria-current={location.pathname === "/" ? "page" : undefined}
              >
                Home
              </a>
              <a
                href="/projects"
                className={`rounded-md px-6 py-2 text-md font-large ${
                  location.pathname === "/projects"
                    ? "bg-blue-800 text-white shadow-md"
                    : "hover:bg-blue-400 hover:text-white hover:shadow-md"
                }`}
                aria-current={
                  location.pathname === "/projects" ? "page" : undefined
                }
              >
                Projects
              </a>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <label
              className={`${
                isChecked ? "bg-blue-900" : "bg-blue-500"
              } relative w-16 h-8 flex items-center rounded-full cursor-pointer transition-colors duration-900`}
              onClick={handleToggle}
            >
              <div
                className={`${
                  isChecked ? "translate-x-9" : "translate-x-1"
                } absolute left-0 w-6 h-6 bg-white rounded-full shadow-md transition-transform duration-300`}
              ></div>
              {isChecked ? (
                <FaMoon className="absolute left-5 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 text-gray-200" />
              ) : (
                <FaSun className="absolute left-11 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 text-yellow-200" />
              )}
            </label>
          </div>
          <div className="relative flex items-center sm:hidden ml-auto">
            <button
              type="button"
              className={`relative inline-flex items-center justify-center rounded-md p-2 ${
                darkMode
                  ? "text-white hover:text-gray-100"
                  : "hover:text-gray-800 text-gray-800"
              }  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-200`}
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen}
              onClick={toggleMobileMenu}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">
                {isMobileMenuOpen ? "Close" : "Open"} main menu
              </span>
              {isMobileMenuOpen ? (
                <svg
                  className={`block h-6 w-6 ${
                    darkMode ? "text-gray-100" : "text-gray-800" 
                  } transform rotate-180`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              ) : (
                <svg
                  className={`block h-6 w-6 ${
                    darkMode ? "text-gray-100" : "text-gray-800"
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>

      <div
        className={`fixed top-16 right-0 w-full h-full transition-opacity ${
          isMobileMenuOpen
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        } duration-300 z-50 ${
          darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
        }`} 
        id="mobile-menu"
      >
        <div className="pt-4 pb-6 px-4 space-y-1">
          <a
            href="/"
            className={`${
              darkMode ? "text-white" : "text-gray-800"
            } hover:bg-gray-200 hover:text-gray-900 block rounded-md px-3 py-2 text-base font-medium w-full`}
          >
            Home
          </a>
          <a
            href="/projects"
            className={`${
              darkMode ? "text-white" : "text-gray-800"
            } hover:bg-gray-200 hover:text-gray-900 block rounded-md px-3 py-2 text-base font-medium w-full`}
          >
            Projects
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
