import React from 'react';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

function Socials() {
  return (
    <div
      className="fixed bottom-4 left-4 flex flex-col items-center space-y-4 z-50"
      style={{ opacity: 0.7, transition: 'opacity 0.3s' }}
    >
      <a
        href="https://www.linkedin.com/in/tan-jun-wei-252098216/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800"
      >
        <FaLinkedin className="text-2xl" />
      </a>
      <a
        href="https://github.com/JunWei25"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-600 hover:text-gray-800"
      >
        <FaGithub className="text-2xl" />
      </a>
      <div className="w-0.5 h-16 bg-gray-400 transform -rotate-0"></div> 
    </div>
  );
}

export default Socials;
