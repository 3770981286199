import React from "react";
import ProgressBar from "../../components/ProgressBar";

const certifications = [
  {
    id: 1,
    title: "Introduction to Cloud Computing",
    description: "Completed the introductory course by IBM on Coursera",
    skills: "Cloud Computing, IBM Cloud, Cloud Native",
    completed: true,
    percentage: 100,
    learned: [
      "Deployed an app using Kubernetes on the IBM cloud interface",
      "Basics of Cloud and Microservices",
    ],
  },
  {
    id: 2,
    title: "Data Science",
    description: "Attended a physical data science course by Crystal Academy",
    skills: "Data Preprocessing, Data Visualization, Machine Learning",
    completed: true,
    percentage: 100,
    learned: [
      "Developed a machine learning airline ticket cancellation prediction model.",
      "Learned the different types of data preprocessing methods.",
    ],
  },
  {
    id: 3,
    title: "Spring Boot 3, Spring 6 & Hibernate for Beginners",
    description:
      "Completed the Spring Boot 3, Spring 6 & Hibernate course by Chad Derby on Udemy.",
    skills: "Spring, Spring Boot, Hibernate",
    completed: true,
    percentage: 100,
    learned: [],
  },
  {
    id: 4,
    title: "Microsoft Azure 900 Fundamentals",
    description:
      "Passed the Microsoft Azure 900 Fundamentals certification exam.",
    skills: "Cloud concepts, Microsoft Azure",
    completed: true,
    percentage: 100,
    learned: [
      "The basics of cloud concepts.",
      "Overview of Microsoft Azure's offerings.",
      "Use cases of Microsoft Azure"
    ],
  },
  {
    id: 5,
    title: "A Complete Guide to the Flutter SDK &amp; Flutter Framework for building native iOS and Android apps",
    description:
      "Currently learning Flutter on Udemy",
    skills: "Flutter, GoogleMaps API, Firebase",
    completed: false,
    percentage: 86,
    learned: [
    ],
  },
];

function MyCertification({ darkMode }) {
  return (
    <section
      id="projectpreview"
      className={`py-16 ${
        darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
      }`}
    >
      <div className="container mx-auto px-5 md:px-12 scroll-smooth flex flex-col">
        <h2
          className={`text-4xl md:text-5xl font-semibold mb-8 font-poppins ${
            darkMode ? "text-white" : "text-blue-800"
          }`}
        >
          Certifications
        </h2>

        <div>
          <h3
            className={`text-xl md:text-2xl font-semibold mb-2 font-poppins ${
              darkMode ? "text-white" : "text-blue-800"
            }`}
          >
            Completed
          </h3>
          {certifications.map((certification) => {
            const isCompleted = certification.completed;
            const progress = certification.percentage;
            const learned = certification.learned;

            const skillsTags = certification.skills
              .split(", ")
              .map((skill, index) => (
                <span
                  key={`skill-${index}`}
                  className={`${
                    darkMode
                      ? "bg-blue-800 text-white"
                      : "bg-blue-100 text-blue-500"
                  } px-2 py-1 rounded-full text-sm font-semibold mr-2 mb-2`}
                >
                  {skill}
                </span>
              ));

            const learnedList = learned.map((item, index) => (
              <li
                key={`learned-${index}`}
                className={`text-sm md:text-base mb-1 ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                - {item}
              </li>
            ));
            if (isCompleted) {
              return (
                <div
                  key={`certification-${certification.id}`}
                  className={`rounded-lg shadow-lg p-6 mb-6 ${
                    darkMode
                      ? "bg-gray-700 text-white"
                      : "bg-white text-gray-800"
                  }`}
                >
                  <h3
                    className={`text-xl md:text-2xl font-semibold mb-2 font-poppins ${
                      darkMode ? "text-white" : "text-gray-800"
                    }`}
                  >
                    {certification.title}
                  </h3>
                  <p
                    className={`text-sm md:text-base mb-2 ${
                      darkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    {certification.description}
                  </p>
                  <div className="flex flex-wrap mb-2">{skillsTags}</div>
                  <ul>{learnedList}</ul>
                  <ProgressBar progress={progress} darkMode={darkMode} />
                </div>
              );
            }
          })}
        </div>
        <hr
          className={`h-px my-8 ${
            darkMode ? "bg-gray-200" : "bg-gray-100"
          } border-0`}
        />
        <div>
          <h3
            className={`text-xl md:text-2xl font-semibold mb-2 font-poppins ${
              darkMode ? "text-white" : "text-blue-800"
            }`}
          >
            What I am currently learning!
          </h3>
          {certifications.map((certification) => {
            const isCompleted = certification.completed;
            const progress = certification.percentage;
            const learned = certification.learned;

            const skillsTags = certification.skills
              .split(", ")
              .map((skill, index) => (
                <span
                  key={`skill-${index}`}
                  className={`${
                    darkMode
                      ? "bg-blue-800 text-white"
                      : "bg-blue-100 text-blue-500"
                  } px-2 py-1 rounded-full text-sm font-semibold mr-2 mb-2`}
                >
                  {skill}
                </span>
              ));

            const learnedList = learned.map((item, index) => (
              <li
                key={`learned-${index}`}
                className={`text-sm md:text-base mb-1 ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                - {item}
              </li>
            ));

            if (!isCompleted) {
              return (
                <div
                  key={`certification-${certification.id}`}
                  className={`rounded-lg shadow-lg p-6 mb-6 ${
                    darkMode
                      ? "bg-gray-700 text-white"
                      : "bg-white text-gray-800"
                  }`}
                >
                  <h3
                    className={`text-xl md:text-2xl font-semibold mb-2 font-poppins ${
                      darkMode ? "text-white" : "text-gray-800"
                    }`}
                  >
                    {certification.title}
                  </h3>
                  <p
                    className={`text-sm md:text-base mb-2 ${
                      darkMode ? "text-gray-300" : "text-gray-600"
                    }`}
                  >
                    {certification.description}
                  </p>
                  <div className="flex flex-wrap mb-2">{skillsTags}</div>
                  <ul>{learnedList}</ul>
                  <ProgressBar progress={progress} darkMode={darkMode} />
                </div>
              );
            }

            return null; // Don't render completed certifications here
          })}
          <div
            className={`text-center mt-8 ${
              darkMode ? "text-white" : "text-gray-500"
            }`}
          >
            {/* <p>
              I don't complete these courses to seek validation but rather I
              firmly believe the quote by Zig Ziglar:
            </p> */}
            <blockquote
              className={`text-xl italic border-l-4 pl-4 py-2 ${
                darkMode
                  ? "border-blue-500 text-gray-400"
                  : "border-blue-500 text-gray-500"
              }`}
            >
              "If you are not willing to learn, no one can help you.
              <br />
              If you are determined to learn, no one can stop you."
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MyCertification;
