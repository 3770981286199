import React from 'react';
import HeroSection from "../HeroSection";
import MyExperience from "../MyExperience";
import ProjectPreview from "../ProjectPreview";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import MyCertification from '../MyCertification';
import Socials from '../../../components/Socials'; 

function HomePage({darkMode}) {
  return (
    <>
      <HeroSection darkMode={darkMode}/>
      <MyExperience darkMode={darkMode}/>
      <ProjectPreview darkMode={darkMode}/>
      <MyCertification darkMode={darkMode}/>
      <Socials /> {/* Include the Socials component */}
      <ScrollToTopButton darkMode={darkMode}/>
    </>
  );
}

export default HomePage;
